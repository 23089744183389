import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "components/layout"
import SEO from "components/seo"
import { useLightVersion } from "utils"

import {
  Content,
  TeamMember,
  TeamMemberName,
  TeamMemberTitle,
  TeamMemberDescription,
  TeamMemberVideoContainer,
  TeamMemberVideo,
  TeamMemberContent,
} from "./_styles"

const TeamPage = (props) => {
  const lightVersion = useLightVersion()

  const { allTeamJson } = useStaticQuery(graphql`
    {
      allTeamJson {
        edges {
          node {
            id
            name
            surname
            title
            description
            mp4 {
              publicURL
            }
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {/* TODO: team page seo */}
      <SEO title="Team" />
      <Content>
        {allTeamJson.edges.map(({ node: teamMember }) => (
          <TeamMember key={teamMember.id}>
            <TeamMemberVideoContainer>
              {lightVersion ? (
                <TeamMemberVideo
                  as={Img}
                  style={{
                    position: "absolute",
                  }}
                  fluid={teamMember.image.childImageSharp.fluid}
                  alt={`${teamMember.name} ${teamMember.surname} : ${teamMember.title}`}
                />
              ) : (
                <TeamMemberVideo
                  // Use only mp4 here because it is smaller than webm
                  mp4={teamMember.mp4.publicURL}
                  muted
                  autoPlay
                  loop
                />
              )}
            </TeamMemberVideoContainer>
            <TeamMemberContent>
              <TeamMemberName>
                <p>
                  {teamMember.name
                    .split("")
                    .map((letter: string, index: number) => (
                      <span key={index}>{letter}</span>
                    ))}
                </p>
                <p>
                  {teamMember.surname
                    .split("")
                    .map((letter: string, index: number) => (
                      <span key={index}>{letter}</span>
                    ))}
                </p>
              </TeamMemberName>
              <TeamMemberTitle>{teamMember.title}</TeamMemberTitle>
              <TeamMemberDescription
                dangerouslySetInnerHTML={{ __html: teamMember.description }}
              />
            </TeamMemberContent>
          </TeamMember>
        ))}
      </Content>
    </Layout>
  )
}

export default TeamPage
