import styled from "styled-components"
import { HEADER_HEIGHT } from "components/Header/styles"
import Video from "components/Video"

export const Content = styled.main`
  background-color: black;
  width: 100%;
  display: flex;
  padding: ${HEADER_HEIGHT + 40}px 0;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  color: white;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${HEADER_HEIGHT + 150}px 0 4rem 0; /* 80 is size of laurs */
  }
`

export const TeamMember = styled.div`
  width: 100%;
  padding-bottom: 2rem;

  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 50%;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${100 / 3}%;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 20%;
  }
`

// Rectangle box
export const TeamMemberVideoContainer = styled.div`
  background-color: white;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`

export const TeamMemberVideo = styled(Video)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const TeamMemberContent = styled.div`
  padding: 1rem;
`

export const TeamMemberName = styled.div`
  margin-top: 1.5rem;
  width: 140px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: ${17 / 16}rem;
  line-height: 2;

  > p {
    display: flex;
    justify-content: space-between;
  }
`

export const TeamMemberTitle = styled.p`
  margin-top: 1rem;
  font-weight: 100;
  font-size: 1rem;
  line-height: 2;
`

export const TeamMemberDescription = styled.p`
  margin-top: 2rem;
  font-weight: bold;
  font-size: ${11 / 16}rem;
  line-height: 3;
  text-align: justify;
`
